import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const AsyncCongregationCreate = lazy(
  () => import('~/containers/CongregationCreate')
);

export default [
  <Route path="/congregations/new" key="/congregations/new">
    <Suspense fallback={<ApplicationSkeleton />}>
      <AsyncCongregationCreate />
    </Suspense>
  </Route>,
];
