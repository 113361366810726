import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { shouldPolyfill as shouldPolyfillPluralRuleLocale } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormatLocale } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { Helmet } from 'react-helmet';
import { appLocales } from '../../i18n';

async function intlRelativeTimeFormatPolyfill(locale: string) {
  const unsupportedLocale = shouldPolyfillRelativeTimeFormatLocale(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-relativetimeformat/polyfill-force');
  switch (locale) {
    case 'en':
      await import('@formatjs/intl-relativetimeformat/locale-data/en');
      return;
    case 'es':
      await import('@formatjs/intl-relativetimeformat/locale-data/es');
      return;
    case 'fr':
      await import('@formatjs/intl-relativetimeformat/locale-data/fr');
      return;
    case 'ru':
      await import('@formatjs/intl-relativetimeformat/locale-data/ru');
      return;
    case 'de':
    default:
      await import('@formatjs/intl-relativetimeformat/locale-data/de');
  }
}

async function intlPluralRulesPolyfill(locale: (typeof appLocales)[number]) {
  const unsupportedLocale = shouldPolyfillPluralRuleLocale(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import('@formatjs/intl-pluralrules/polyfill-force');
  switch (locale) {
    case 'en':
      await import('@formatjs/intl-pluralrules/locale-data/en');
      return;
    case 'es':
      await import('@formatjs/intl-pluralrules/locale-data/es');
      return;
    case 'fr':
      await import('@formatjs/intl-pluralrules/locale-data/fr');
      return;
    case 'ru':
      await import('@formatjs/intl-pluralrules/locale-data/ru');
      return;
    case 'de':
    default:
      await import('@formatjs/intl-pluralrules/locale-data/de');
  }
}

// https://fettblog.eu/typescript-array-includes/#option-2%3A-a-helper-with-type-assertions
function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T);
}

const getLocaleFromCookie = () => {
  const languageCookie = document.cookie
    .split(';')
    .find((item) => item.trim().startsWith('.AspNetCore.Culture='));
  const match = languageCookie
    ? decodeURIComponent(languageCookie).match(/uic=([a-z]+)/)
    : undefined;
  const parsedLocale = match?.[1];
  if (!parsedLocale || !includes(appLocales, parsedLocale)) {
    if (typeof window !== 'undefined') {
      window.location.assign('/Identity/Account/Login');
    }
    throw new Error(`Unknown locale ${parsedLocale}`);
  }
  return parsedLocale;
};

const useUILanguageState = () => {
  const [locale, setLocale] = useState<(typeof appLocales)[number] | null>(
    () => {
      const parsedLocale = getLocaleFromCookie();
      if (moment.locale() === parsedLocale) {
        return parsedLocale;
      }
      return null;
    }
  );
  const handleSetLocale = async (nextLocale: (typeof appLocales)[number]) => {
    if (import.meta.env.NODE_ENV !== 'test') {
      if (nextLocale !== 'en') {
        // en is support by moment by default
        await import(`./locales/${nextLocale}.ts`);
      }
      await intlPluralRulesPolyfill(nextLocale);
      await intlRelativeTimeFormatPolyfill(nextLocale);
    }
    moment.locale(nextLocale);
    moment.updateLocale(nextLocale, {
      week: {
        dow: 1,
      },
    });
    setLocale(nextLocale);
  };
  useEffect(() => {
    const parsedLocale = getLocaleFromCookie();
    handleSetLocale(parsedLocale);
  }, []);
  return {
    locale,
    setLocale: async (newLocale: (typeof appLocales)[number]) => {
      const origin = import.meta.env.TEST
        ? 'http://localhost:3000'
        : window.location.origin;
      const response = await fetch(
        `${origin}/api/Dashboards/my?language=${newLocale}`
      );
      if (!response.ok) {
        return;
      }
      await handleSetLocale(newLocale);
    },
  };
};

export const UILanguageContext = React.createContext<
  | ({ locale: (typeof appLocales)[number] } & Pick<
      ReturnType<typeof useUILanguageState>,
      'setLocale'
    >)
  | null
>(null);

export const UILanguageProvider = (props: { children: React.ReactNode }) => {
  const { locale, setLocale } = useUILanguageState();
  const value = useMemo(
    () => (locale ? { locale, setLocale } : null),
    [locale, setLocale]
  );
  return locale ? (
    <UILanguageContext.Provider value={value}>
      <Helmet>
        <html lang={value?.locale} />
      </Helmet>
      {props.children}
    </UILanguageContext.Provider>
  ) : null;
};

export const useUILanguage = () => {
  const context = useContext(UILanguageContext);
  if (!context) {
    throw new Error('Please wrap your component with the UILanguageProvider');
  }
  return context;
};
