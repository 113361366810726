/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux';
import formReducer from 'redux-form/lib/reducer';

import { LOGOUT } from '~/containers/App/constants';
import drawerMenuReducer from '~/containers/DrawerMenu/reducer';
import snackbarReducer from '~/containers/Snackbar/reducer';
import entitiesReducer from './entitiesReducer';

const createAppReducer = (asyncReducers) =>
  combineReducers({
    entities: entitiesReducer,
    drawerMenu: drawerMenuReducer,
    snackbar: snackbarReducer,
    form: formReducer,
    ...asyncReducers,
  });

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
  const appReducer = createAppReducer(asyncReducers);
  const rootReducer = (state, action) => {
    // this resets the store whenever the user logs out so that nobody else can
    // get the users data after he logged out.
    // TODO there is still one unsolved edge case though:
    // when the user logs in, requests get started, the user logs out and after
    // that the started requests finish and the received data gets put into the
    // store.
    if (action.type === LOGOUT) {
      state = undefined;
    }

    return appReducer(state, action);
  };
  return rootReducer;
}
