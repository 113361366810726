import { normalize } from '~/modules/normalizr';
import * as Sentry from '@sentry/react';
import { fork, take, put, call, select } from 'redux-saga/effects';
import { DELETE_ENTITY, selectEntity } from '~/entitiesReducer';
import ApiError from '~/utils/api-error';
import * as configs from '../../configs';
import { CALL_API } from './constants';
import { selectInstanceFetchSlice } from '../FetchEntities/selectors';
import { FETCH_ENTITIES_SUCCESS } from '../FetchEntities/constants';

export const getApiBaseUrl = () => {
  if (import.meta.env.TEST) {
    return 'http://localhost:3000/api/';
  }
  return import.meta.env.PROD
    ? configs.production.api
    : window.location.origin + configs.development.api;
};

export function* callApi({ endpoint, config: customConfig = {}, schema }) {
  let config = { ...customConfig };

  const BASE_URL = getApiBaseUrl();
  const url = BASE_URL + endpoint;

  config = {
    ...config,
    headers: {
      ...config.headers,
      Accept: 'application/json',
      ...(config.method &&
      ['post', 'put'].includes(config.method.toLowerCase()) &&
      !config.skipContentType
        ? { 'Content-Type': 'application/json' }
        : {}),
    },
  };
  const response = yield call(fetch, url, config);

  if (response.status === 401) {
    window.location.href = `/Identity/Account/Login`;
  }

  // We just try to parse json, if the server sent us some.
  // Empty responses are possible too, for example on DELETE requests.
  const contentType = response.headers.get('content-type');
  const isJSON = contentType && contentType.includes('application/json');
  const isText = contentType && contentType.includes('text/');

  // If there was no response, we return a empty object as default.
  let parsedResponse;
  if (isJSON) {
    parsedResponse = yield call([response, response.json]);
  } else if (isText) {
    parsedResponse = yield call([response, response.text]);
  } else {
    parsedResponse = {};
  }

  if (!response.ok) {
    if (isJSON) {
      throw new ApiError(parsedResponse, response.status);
    }
    throw new ApiError(undefined, response.status);
  }

  let yieldValue = parsedResponse;

  if (schema && isJSON) {
    // noramlize response if there is one, and we got a schema defined for it
    if (Array.isArray(parsedResponse)) {
      yieldValue = yield call(normalize, parsedResponse, [schema]);
      try {
        yieldValue.totalCount =
          parseInt(response.headers.get('total-count'), 10) || 0;
      } catch (_e) {
        yieldValue.totalCount = 0;
      }
    } else {
      yieldValue = yield call(normalize, parsedResponse, schema);
    }
  }
  return yieldValue;
}

export function* apiWatcher() {
  while (true) {
    const action = yield take(CALL_API);
    // we fork here, to support multiple call-api
    // actions at once
    yield fork(handleApiAction, action);
  }
}

export function* handleOptimisticUpdate(action) {
  if (!action.meta || !action.meta.entityId || !action.meta.schema) return null;
  const selector = yield call(selectEntity);
  const oldState = yield select(
    selector,
    action.meta.schema._key,
    action.meta.entityId
  );
  const successType = action.payload.types[1];
  const normalizedShift = yield call(
    normalize,
    JSON.parse(action.payload.config.body),
    action.meta.schema
  );
  yield put({
    type: successType,
    payload: normalizedShift,
    meta: action.meta,
  });
  return oldState;
}

export function* handleOptimisticDelete(action) {
  if (!action.meta || !action.meta.schema || !action.meta.entityId) return {};
  const { entityId } = action.meta;
  const selector = yield call(selectEntity);
  const oldState = yield select(selector, action.meta.schema._key, entityId);
  let oldInstance;
  if (action.meta.instanceName) {
    const instanceSelector = yield call(
      selectInstanceFetchSlice,
      action.meta.instanceName
    );
    oldInstance = yield select(instanceSelector);
  }
  // now we remove this thing from our entities
  yield put({
    type: DELETE_ENTITY,
    meta: {
      schema: action.meta.schema,
      instanceName: action.meta.instanceName,
      entityId,
    },
  });
  return { oldState, oldInstance };
}

export function* revertOptimisticUpdate(action, oldState, oldInstance) {
  const successType = action.payload.types[1];
  const normalizedShift = yield call(normalize, oldState, action.meta.schema);
  yield put({
    type: successType,
    payload: normalizedShift,
  });
  if (oldInstance && action.meta && action.meta.instanceName) {
    yield put({
      type: FETCH_ENTITIES_SUCCESS,
      payload: oldInstance,
      meta: {
        instanceName: action.meta.instanceName,
      },
    });
  }
}

export function* handleApiAction(action) {
  const {
    payload: { endpoint, types, config, callback },
    meta,
    meta: { schema, update, shouldTransformApiErrorToSubmissionError } = {
      shouldTransformApiErrorToSubmissionError: false,
    },
  } = action;
  const [requestType, successType, errorType] = types;
  let oldState;
  let oldInstance;
  if (config && config.method === 'PUT') {
    oldState = yield call(handleOptimisticUpdate, action);
  } else if (config && config.method === 'DELETE') {
    const optimisticDeleteResult = yield call(handleOptimisticDelete, action);
    oldState = optimisticDeleteResult.oldState;
    oldInstance = optimisticDeleteResult.oldInstance;
  } else {
    yield put({ type: requestType, meta });
  }
  try {
    // we should use fork here to allow two events at once
    const response = yield call(callApi, {
      endpoint,
      config,
      schema,
    });
    if (update) {
      const updatedEntities = yield call(update, response);
      yield put({
        type: successType,
        payload: {
          entities: { ...response.entities, ...updatedEntities.entities },
          result: response.result,
          totalCount: response.totalCount,
        },
        meta,
      });
    } else {
      yield put({
        type: successType,
        payload: response,
        meta,
      });
    }
    if (callback) {
      callback(undefined, response);
    }
  } catch (e) {
    const isFailedToFetchError =
      e && e.message && e.message.includes('Failed to fetch');
    // this condition tries to catch only programmer errors and throw those
    // as exceptions, so we can track if something went wrong.
    if (e && !(e instanceof ApiError) && !isFailedToFetchError && e.stack) {
      // as redux saga will for some reason swallow these errors we log them
      // to sentry manually
      Sentry.captureException(e);
      if (
        import.meta.env.NODE_ENV === 'development' ||
        import.meta.env.NODE_ENV === 'test'
      ) {
        console.error(e);
      }
      throw e;
    }
    yield put({
      type: errorType,
      payload: e,
      meta,
    });
    if (config && ['PUT', 'DELETE'].includes(config.method) && oldState) {
      yield call(revertOptimisticUpdate, action, oldState, oldInstance);
    }
    if (callback) {
      const error = shouldTransformApiErrorToSubmissionError
        ? e.toReduxFormError()
        : e;
      callback(error);
    }
  }
}

export default [apiWatcher];
