import classnames from 'classnames';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ErrorPage from '~/components/ErrorPage';

import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './styles.module.css';

type LoadingViewProps = {
  error?: string;
  hideLabel?: boolean;
  onGray?: boolean;
};

export const LoadingView = (props: LoadingViewProps) => {
  const intl = useIntl();
  if (props.error) {
    console.error(props.error);
  }
  return props.error ? (
    <ErrorPage onRetry={() => window.location.reload()} />
  ) : (
    <div
      data-testid="loading-view"
      className={classnames(styles.wrapper, { [styles.gray]: props.onGray })}
    >
      <CircularProgress
        size={60}
        aria-label={
          props.hideLabel ? intl.formatMessage(messages.loading) : undefined
        }
      />
      {!props.hideLabel && (
        <Typography variant="h4" className={styles.label}>
          {intl.formatMessage(messages.loading)}
        </Typography>
      )}
    </div>
  );
};

export default LoadingView;
