import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import ApplicationSkeleton from '~/components/ApplicationSkeleton';

const Availability = lazy(() => import('~/containers/Availability'));

export default [
  <Route path="/availability" key="/availability">
    <Suspense fallback={<ApplicationSkeleton />}>
      <Availability />
    </Suspense>
  </Route>,
];
