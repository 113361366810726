/**
 *
 * ErrorPage
 *
 */

import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import BugReportIcon from '@mui/icons-material/BugReport';
import RefreshIcon from '@mui/icons-material/Refresh';
import { grey } from '@mui/material/colors';
import commonMessages from '~/containers/App/messages';
import Typography from '@mui/material/Typography';
import Inset from '~/modules/ui-kit/spacings/inset/inset';
import Stack from '~/modules/ui-kit/spacings/stack/stack';
import messages from './messages';
import styles from './styles.module.css';

type ErrorPageProps = {
  onRetry: () => void;
  isLoading?: boolean;
};

const ErrorPage = ({ onRetry, isLoading = false }: ErrorPageProps) => (
  <div className={styles.page}>
    <Inset>
      <Stack scale="l" alignItems="center">
        <BugReportIcon
          style={{
            height: '128px',
            width: '128px',
            color: grey[500],
          }}
        />
        <Typography>
          <FormattedMessage {...messages.header} />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<RefreshIcon />}
          onClick={onRetry}
          disabled={isLoading}
          style={{ marginTop: 16 }}
        >
          {isLoading ? (
            <FormattedMessage {...commonMessages.loading} />
          ) : (
            <FormattedMessage {...messages.retry} />
          )}
        </Button>
      </Stack>
    </Inset>
  </div>
);

ErrorPage.displayName = 'ErrorPage';

export default ErrorPage;
